import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-hot-toast";
import { defaultApi } from "../../../services/api";
import Message from "../../../utils/message/Message";
import {
  CheckAmphoe,
  CheckDeedNo,
  CheckFactoryName,
  CheckFactoryType,
  CheckLat,
  CheckLicenseeNumber,
  CheckLicenseeRegister,
  CheckLng,
  CheckOwner,
  CheckProposalNo,
  CheckProvince,
  CheckTambol,
} from "../../../utils/validation_input/Validation";
import image from "../../../assets/img/image";
import { QueryGetAll } from "../../../helpers/api.helper";
import styles from "../scss/ModalHowTo.module.scss";

const initData = {
  lat: "",
  lng: "",
  addressRegioncode: "",
  addressProvince: "",
  addressAmphoe: "",
  addressTambol: "",
  addressProvCode: "",
  addressAmpCode: "",
  addressTamCode: "",
  name: "",
  owner: "",
  factoryTypeid: "",
  manager: "",
  proposalNo: "",
  licensee: "",
  licenseeNumber: "",
  licenseeRegister: "",
  DeedNo: "",
  factoryStatusid: 1,
  address: null,
  point: "",
};

const AddPowerPlants = ({ _fetchData, _checkUserCreatedFac }) => {
  const [_data, setData] = useState(initData);
  const [factoryType, setFactoryType] = useState([]);
  const [allProvince, setAllProvince] = useState([]);
  const [allAmphoe, setAllAmphone] = useState([]);
  const [allTambol, setAllTambol] = useState([]);
  const [validErr, setValidErr] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ..._data, [name]: value });
  };
  const onChangeMulti = (e) => {
    const value = e.target.value;
    allProvince.forEach((item) => {
      if (item.provName === value) {
        setData({
          ..._data,
          addressProvince: value,
          addressProvCode: item.provCode,
          addressRegioncode: item.region,
          addressAmphoe: "",
          addressAmpCode: "",
        });
      }
    });
    allAmphoe.forEach((item) => {
      if (item.ampName === value) {
        setData({
          ..._data,
          addressAmphoe: value,
          addressAmpCode: item.ampCode,
          addressTambol: "",
          addressTamCode: "",
        });
      }
    });
    allTambol.forEach((item) => {
      if (item.tamName === value) {
        setData({
          ..._data,
          addressTambol: value,
          addressTamCode: item.tamCode,
        });
      }
    });
  };

  const createFactory = async (e) => {
    e.preventDefault();
    if (CheckLat(_data.lat)) return setValidErr(true);
    if (CheckLng(_data.lng)) return setValidErr(true);
    if (CheckProvince(_data.addressProvince)) return setValidErr(true);
    if (CheckAmphoe(_data.addressAmphoe)) return setValidErr(true);
    if (CheckTambol(_data.addressTambol)) return setValidErr(true);
    if (CheckFactoryName(_data.name)) return setValidErr(true);
    if (CheckOwner(_data.owner)) return setValidErr(true);
    if (CheckFactoryType(_data.factoryTypeid)) return setValidErr(true);
    if (CheckProposalNo(_data.proposalNo)) return setValidErr(true);
    if (CheckLicenseeNumber(_data.licenseeNumber)) return setValidErr(true);
    if (CheckLicenseeRegister(_data.licenseeRegister)) return setValidErr(true);
    if (CheckOwner(_data.licensee)) return setValidErr(true);
    if (CheckDeedNo(_data.DeedNo)) return setValidErr(true);

    /* เช็คว่ามีโรงไฟฟ้าที่ ผู้ใช้คนนั้นๆ มีการสร้างแล้วหรือไม่ 
    ถ้ามีให้โชว์ข้อความบอก : START */
    if (_checkUserCreatedFac)
      return toast.error(Message.notify.checkUserCreatedFac, {
        duration: 5000,
      });
    /* เช็คว่ามีโรงไฟฟ้าที่ ผู้ใช้คนนั้นๆ มีการสร้างแล้วหรือไม่ 
    ถ้ามีให้โชว์ข้อความบอก : END */

    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(_data).forEach((key) => {
      if (key === "lat") {
        params[key] = +_data.lat;
      } else if (key === "lng") {
        params[key] = +_data.lng;
      } else if (key === "factoryTypeid") {
        params[key] = +_data.factoryTypeid;
      } else if (key === "FactoryStatusid") {
        params[key] = +_data.factoryStatusid;
      } else if (key === "manager") {
        params[key] = JSON.stringify([]);
      } else if (key === "point") {
        params[key] = [[+_data.lat, +_data.lng]];
      } else {
        params[key] = _data[key];
      }
    });
    try {
      const res = await defaultApi.createFactory(params);
      console.log(res.statusText);
      setValidErr(false);
      toast.success(Message.notify.add_factory_success, { duration: 3000 });
      // Reset input
      setData(initData);
      // Fetch api
      _fetchData();
      // Close modal
      document.querySelector("#close_addPpF").click();
    } catch (error) {
      console.log(error);
      setValidErr(true);
      toast.error(Message.notify.add_factory_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };

  const getFactoryType = async () => {
    try {
      const res = await defaultApi.getFactoryType();
      setFactoryType(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllProvince = async () => {
    try {
      const res = await defaultApi.getAllProvince();

      setAllProvince(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllAmphoe = useCallback(async () => {
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=provCode%20eq%20%27${_data.addressProvCode}%27`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllAmphoe(_queryAll);
      // Create a new Set with unique ampNames
      const uniqueAmpNames = [
        ...new Set(res.data.datas.map((item) => item.ampName)),
      ];

      // Create a new array of objects with unique ampNames
      const uniqueData = uniqueAmpNames.map((ampName) => {
        return res.data.datas.find((item) => item.ampName === ampName);
      });

      setAllAmphone(uniqueData);
    } catch (error) {
      console.log(error);
    }
  }, [_data]);
  const getAllTambol = useCallback(async () => {
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=ampCode%20eq%20%27${_data.addressAmpCode}%27
      %20and%20ampName%20eq%20%27${_data.addressAmphoe}%27`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllTambol(_queryAll);
      // Create a new Set with unique ampNames
      const uniqueAmpNames = [
        ...new Set(res.data.datas.map((item) => item.tamName)),
      ];

      // Create a new array of objects with unique ampNames
      const uniqueData = uniqueAmpNames.map((tamName) => {
        return res.data.datas.find((item) => item.tamName === tamName);
      });

      setAllTambol(uniqueData);
    } catch (error) {
      console.log(error);
    }
  }, [_data]);

  useEffect(() => {
    // Use functions
    getFactoryType();
    getAllProvince();
    getAllAmphoe();
    getAllTambol();
  }, [getAllAmphoe, getAllTambol]);
  return (
    <>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="staticBackdropLabel">
                เพิ่มโรงไฟฟ้า
              </h4>
            </div>
            <div className="modal-body">
              <form onSubmit={createFactory} className="form_input">
                <div className="input_flex">
                  <div className="input_1">
                    <h5>
                      ละติจูด <sup className="asterisk">*</sup>
                    </h5>
                    <input
                      type="text"
                      name="lat"
                      value={_data.lat}
                      className={`${
                        validErr && _data.lat === "" ? "error" : ""
                      }`}
                      onChange={onChange}
                    />
                    {validErr && (
                      <small className="err_message">
                        {CheckLat(_data.lat)}
                      </small>
                    )}
                  </div>
                  <div className="input_2">
                    <h5>
                      ลองจิจูด <sup className="asterisk">*</sup>
                    </h5>
                    <input
                      type="text"
                      name="lng"
                      value={_data.lng}
                      className={`${
                        validErr && _data.lng === "" ? "error" : ""
                      }`}
                      onChange={onChange}
                    />
                    {validErr && (
                      <small className="err_message">
                        {CheckLng(_data.lng)}
                      </small>
                    )}
                  </div>
                </div>
                <span
                  className="info_how_to"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop99"
                >
                  วิธีหาค่าพิกัด
                </span>
                <div className="input_">
                  <h5>
                    จังหวัด <sup className="asterisk">*</sup>
                  </h5>
                  <select
                    name="addressProvince"
                    value={_data.addressProvince}
                    className={`${
                      validErr && _data.addressProvince === "" ? "error" : ""
                    }`}
                    onChange={onChangeMulti}
                  >
                    <option style={{ display: "none" }}>เลือกจังหวัด</option>
                    {allProvince
                      .sort((a, b) => a.provName.localeCompare(b.provName))
                      .map((item) => {
                        return (
                          <option key={item.gid} value={item.provName}>
                            {item.provName}
                          </option>
                        );
                      })}
                  </select>
                  {validErr && (
                    <small className="err_message">
                      {CheckProvince(_data.addressProvince)}
                    </small>
                  )}
                </div>
                <div className="input_">
                  <h5>
                    อำเภอ <sup className="asterisk">*</sup>
                  </h5>
                  <select
                    name="addressAmphoe"
                    value={_data.addressAmphoe}
                    className={`${
                      validErr && _data.addressAmphoe === "" ? "error" : ""
                    }`}
                    onChange={onChangeMulti}
                  >
                    <option style={{ display: "none" }}>เลือกอำเภอ</option>
                    {allAmphoe
                      .sort((a, b) => a.ampName.localeCompare(b.ampName))
                      .map((item) => {
                        return (
                          <option key={item.gid} value={item.ampName}>
                            {item.ampName}
                          </option>
                        );
                      })}
                  </select>
                  {validErr && (
                    <small className="err_message">
                      {CheckAmphoe(_data.addressAmphoe)}
                    </small>
                  )}
                </div>
                <div className="input_">
                  <h5>
                    ตำบล <sup className="asterisk">*</sup>
                  </h5>
                  <select
                    name="addressTambol"
                    value={_data.addressTambol}
                    className={`${
                      validErr && _data.addressTambol === "" ? "error" : ""
                    }`}
                    onChange={onChangeMulti}
                  >
                    <option style={{ display: "none" }}>เลือกตำบล</option>
                    {allTambol
                      .sort((a, b) => a.tamName.localeCompare(b.tamName))
                      .map((item) => {
                        return (
                          <option key={item.gid} value={item.tamName}>
                            {item.tamName}
                          </option>
                        );
                      })}
                  </select>
                  {validErr && (
                    <small className="err_message">
                      {CheckTambol(_data.addressTambol)}
                    </small>
                  )}
                </div>
                <div className="input_">
                  <h5>
                    ชื่อโรงไฟฟ้า <sup className="asterisk">*</sup>
                  </h5>
                  <input
                    type="text"
                    name="name"
                    value={_data.name}
                    className={`${
                      validErr && _data.name === "" ? "error" : ""
                    }`}
                    onChange={onChange}
                  />
                  {validErr && (
                    <small className="err_message">
                      {CheckFactoryName(_data.name)}
                    </small>
                  )}
                </div>
                <div className="input_">
                  <h5>
                    ชื่อผู้ประกอบการ <sup className="asterisk">*</sup>
                  </h5>
                  <input
                    type="text"
                    name="owner"
                    value={_data.owner}
                    className={`${
                      validErr && _data.owner === "" ? "error" : ""
                    }`}
                    onChange={onChange}
                  />
                  {validErr && (
                    <small className="err_message">
                      {CheckOwner(_data.owner)}
                    </small>
                  )}
                </div>
                <div className="input_">
                  <h5>
                    ประเภทโรงไฟฟ้า <sup className="asterisk">*</sup>
                  </h5>
                  <select
                    name="factoryTypeid"
                    className={`${
                      validErr && _data.factoryTypeid === "" ? "error" : ""
                    }`}
                    value={_data.factoryTypeid}
                    onChange={onChange}
                  >
                    <option style={{ display: "none" }}>
                      เลือกประเภทโรงไฟฟ้า
                    </option>
                    ;
                    {factoryType.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {validErr && (
                    <small className="err_message">
                      {CheckFactoryType(_data.factoryTypeid)}
                    </small>
                  )}
                </div>
                <div className="input_">
                  <h5>
                    เลขที่คำเสนอขอขายไฟฟ้า <sup className="asterisk">*</sup>
                  </h5>
                  <input
                    type="text"
                    name="proposalNo"
                    value={_data.proposalNo}
                    className={`${
                      validErr && _data.proposalNo === "" ? "error" : ""
                    }`}
                    onChange={onChange}
                  />
                  {validErr && (
                    <small className="err_message">
                      {CheckProposalNo(_data.proposalNo)}
                    </small>
                  )}
                </div>
                <div className="input_flex">
                  <div className="input_1">
                    <h5>
                      เลขที่ใบอนุญาติ <sup className="asterisk">*</sup>
                    </h5>
                    <input
                      type="text"
                      name="licenseeNumber"
                      value={_data.licenseeNumber}
                      className={`${
                        validErr && _data.licenseeNumber === "" ? "error" : ""
                      }`}
                      onChange={onChange}
                    />
                    {validErr && (
                      <small className="err_message">
                        {CheckLicenseeNumber(_data.licenseeNumber)}
                      </small>
                    )}
                  </div>
                  <div className="input_2">
                    <h5>
                      เลขทะเบียนใบอนุญาต <sup className="asterisk">*</sup>
                    </h5>
                    <input
                      type="text"
                      name="licenseeRegister"
                      value={_data.licenseeRegister}
                      className={`${
                        validErr && _data.licenseeRegister === "" ? "error" : ""
                      }`}
                      onChange={onChange}
                    />
                    {validErr && (
                      <small className="err_message">
                        {CheckLicenseeRegister(_data.licenseeRegister)}
                      </small>
                    )}
                  </div>
                </div>
                <div className="input_">
                  <h5>
                    ผู้รับอนุญาตตามสัญญาซื้อขายไฟฟ้า{" "}
                    <sup className="asterisk">*</sup>
                  </h5>
                  <input
                    type="text"
                    name="licensee"
                    value={_data.licensee}
                    className={`${
                      validErr && _data.licensee === "" ? "error" : ""
                    }`}
                    onChange={onChange}
                  />
                  {validErr && (
                    <small className="err_message">
                      {CheckOwner(_data.licensee)}
                    </small>
                  )}
                </div>
                <div className="input_">
                  <h5>
                    เลขที่โฉนด <sup className="asterisk">*</sup>
                  </h5>
                  <input
                    type="text"
                    name="DeedNo"
                    value={_data.DeedNo}
                    className={`${
                      validErr && _data.DeedNo === "" ? "error" : ""
                    }`}
                    onChange={onChange}
                  />
                  {validErr && (
                    <small className="err_message">
                      {CheckDeedNo(_data.DeedNo)}
                    </small>
                  )}
                </div>
                <div className="button_center">
                  <button type="submit" className="button_sm">
                    บันทึก
                  </button>
                  <button
                    type="button"
                    id="close_addPpF"
                    className="button_sm"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setData(initData);
                      setValidErr(false);
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ModalHowTo />
    </>
  );
};

const ModalHowTo = () => {
  return (
    <div
      className={`modal fade ${styles.modal_how_to}`}
      id="staticBackdrop99"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content" style={{ minHeight: "auto" }}>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              วิธีการหาพิกัดตำแหน่งที่ดิน
            </h5>
          </div>
          <div className="modal-body">
            <div className="box_">
              <p>
                1. เข้า <img src={image.how_to_coor1_1} alt="" /> Google Maps
                ผ่านเว็บบราวน์เซอร์ <img src={image.how_to_coor1_2} alt="" />
              </p>
            </div>
            <div className="box_">
              <p>2. ทำการเปลี่ยนรูปแบบแผนที่เป็นแบบ ภาพถ่ายดาวเทียม</p>
              <div className="img_box">
                <img src={image.how_to_coor2} alt="" />
              </div>
            </div>
            <div className="box_">
              <p>
                3. จากนั้นค้นหาที่ช่องค้นหา ด้วย ชื่อตำบล อำเภอ จังหวัด
                หรือสถานที่ใกล้เคียงกับ ตำแหน่งของโฉนดที่ดิน
              </p>
              <div className="img_box_flex">
                <div className="img_">
                  <img src={image.how_to_coor3_1} alt="" />
                </div>
                <p>หรือ</p>
                <div className="img_">
                  <img src={image.how_to_coor3_2} alt="" />
                </div>
              </div>
            </div>
            <div className="box_">
              <p>
                4. เมื่อพบตำแหน่งที่ดินแล้ว ให้คลิกขวาในพื้นที่ ที่ดินตามโฉนด
                จะปรากฎ พิกัดละติจูด และลองจิจูด
              </p>
              <div className="img_box">
                <img src={image.how_to_coor4} alt="" />
              </div>
            </div>
            <div className="box_">
              <p>5. คลิกที่เลขพิกัด เพื่อคัดลอก</p>
            </div>
            <div className="box_">
              <p>
                6. นำมาใส่ในข้อมูลพิกัด พร้อมทั้งลบ เว้นวรรคหลัง ,
                เพื่อให้ตรงตามรูปแบบ
              </p>
              <div className="img_box" style={{ overflowX: "auto" }}>
                <img src={image.how_to_coor6} alt="" />
              </div>
            </div>

            <div className="btn_center">
              <button type="button" className="btn_" data-bs-dismiss="modal">
                กลับ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPowerPlants;

import React from "react";
import HighCharts from "../../../../components/Highcharts/HighCharts";
import styles from "../scss/ResourceHarvest.module.scss";
import { formatterXaxis_1, formatterYaxis } from "../../../../helpers/suffixchart.helper";

const ChartResourceHarvest = React.forwardRef((prop, ref) => {
  try {
    const ranges = [
      { divider: 1e18, suffix: "E" },
      { divider: 1e15, suffix: "P" },
      { divider: 1e12, suffix: "T" },
      { divider: 1e9, suffix: "G" },
      { divider: 1e6, suffix: "M" },
      { divider: 1e3, suffix: "K" },
    ];

    let monthNameLst = [
      "",
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ];

    const dataX = prop?.datain?.enterpriseTargetHarvest;
    const labelX = [];
    const area = [];
    const target = [];
    if (dataX?.length > 0) {
      for (let index = 0; index < dataX?.length; index++) {
        const element = dataX[index];
        // const [year, month] = element?.label.split(".")
        // lebelX[index] = (Number(year) + 543) + " / " + monthNameLst[Number(month)]
        labelX[index] = element?.label;
      }

      for (let index = 0; index < dataX?.length; index++) {
        const element = dataX[index];
        let jsObj = null;
        if (typeof element?.value === "string") {
          jsObj = JSON.parse(element?.value);
          area[index] = jsObj?.area || 0;
          target[index] = jsObj?.target || 0;
        } else {
          jsObj = element?.value;
          area[index] = jsObj?.area || 0;
          target[index] = jsObj?.target || 0;
        }
      }
    }

    const options = {
      chart: {
        type: "column",
        height: 470,
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: labelX,
        labels: {
          style: {
            fontSize: "13px",
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "จำนวน",
        },
        labels: {
          style: {
            formatter: formatterYaxis,
            fontSize: "13px",
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "18px",
        },
        enabled: true,
        shared: true,
        split: false,
        formatter: function () {
          var point = this;
          let i = "";
          for (let index = 0; index < point.points.length; index++) {
            const element = point.points[index];
            let unit = "";
            if (element.series.name === "พื้นที่(ไร่)") {
              unit = "ไร่";
            } else {
              unit = "ตัน";
            }
            if (index === 0) {
              i =
                i +
                (point.x +
                  "<br/><b>" +
                  element.series.name +
                  "</b> " +
                  Number(element.y).toLocaleString() +
                  " " +
                  unit);
            } else {
              i =
                i +
                ("<br/><b>" +
                  element.series.name +
                  "</b> " +
                  Number(element.y).toLocaleString() +
                  " " +
                  unit);
            }
          }
          return i;
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "16px",
            },
            formatter: formatterXaxis_1,
          },
          enableMouseTracking: true,
        },
        series: {
          lineWidth: 3,
          marker: {
            enabled: false,
          },
        },
      },
      exporting: {
        chartOptions: {
          plotOptions: {
            column: {
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: "6px",
                },
                // formatter: function () {
                //   for (let i = 0; i < ranges.length; i++) {
                //     if (this.y >= ranges[i].divider) {
                //       return (
                //         Number(this.y / ranges[i].divider).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ranges[i].suffix
                //       );
                //     }
                //   }
                //   if (Number(this.y) === 0) {
                //     return null
                //     //return this.y.toString();
                //   } else {
                //     return Number(this.y).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                //   }
                // },
              },
              enableMouseTracking: true,
            },
          },
          xAxis: {
            labels: {
              style: {
                fontSize: "6px",
              },
            },
          },
          yAxis: {
            labels: {
              style: {
                fontSize: "6px",
              },
            },
          },
        },
        enabled: false,
      },
      series: [
        {
          name: "พื้นที่(ไร่)",
          data: area,
        },
        {
          name: "คาดการณ์เก็บเกี่ยว(ตัน)",
          data: target,
        },
      ],
    };

    return <HighCharts ref={ref} options={options} />;
  } catch (error) {}
});

export default ChartResourceHarvest;
